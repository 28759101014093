import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Switch,
  FormControlLabel,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import API_URL from '../../config';

const UpdateForm = ({
  open,
  onClose,
  article,
  onUpdate,
  categories: initialCategories = [],
  tags: initialTags = [],
  authors: initialAuthors = [],
  photos: initialPhotos = []
}) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [categories, setCategories] = useState(initialCategories);
  const [suggestedAuthors, setSuggestedAuthors] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(initialTags);
  const [selectedAuthors, setSelectedAuthors] = useState(initialAuthors);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Store the selected photo

  const resetForm = () => {
    setSelectedArticle(null);
    setIsPublished(false);
    setSelectedTags(initialTags);
    setSelectedAuthors(initialAuthors);
    setSelectedCategory(null);
    setSelectedPhoto(null); // Reset selected photo
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, authorsResponse, tagsResponse] = await Promise.all([
          axios.get(`${API_URL}/api/categories`),
          axios.get(`${API_URL}/api/authors`),
          axios.get(`${API_URL}/api/tags`),
        ]);

        setCategories(categoriesResponse.data?.data || []);
        setSuggestedAuthors(authorsResponse.data?.authors || []);
        setSuggestedTags(tagsResponse.data?.data || []);
      } catch (error) {
        toast.error("Error fetching data!");
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (article) {
      setSelectedArticle({
        ...article,
        tags: article.tags ? article.tags.join(', ') : '',
      });
      setIsPublished(article.status === 'published');
      setSelectedTags(article.tags ? article.tags.map(tag => ({ value: tag._id, label: tag.name })) : []);
      setSelectedCategory(article.category ? { value: article.category._id, label: article.category.name } : null);
      setSelectedAuthors(article.authors ? article.authors.map(author => ({ value: author._id, label: `${author.firstName} ${author.lastName}` })) : []);
      setSelectedPhoto(null); // Reset selected photo on article load
    }
  }, [article]);
  const handleUpdate = async () => {
    if (!selectedArticle?.headline || !selectedArticle?.content || !selectedCategory) {
        toast.error('Please fill in all the required fields.');
        return;
    }

    setIsUpdating(true);
    const updatedArticle = new FormData();
    updatedArticle.append('headline', selectedArticle.headline);
    updatedArticle.append('subheadline', selectedArticle.subheadline);
    updatedArticle.append('content', selectedArticle.content);
    updatedArticle.append('status', isPublished ? 'published' : 'draft');
    updatedArticle.append('youtubeLink', selectedArticle.youtubeLink);

    // Handle selectedTags
    const tagsArray = selectedTags.length > 0 ? selectedTags.map(tag => tag.value) : article.selectedTags.map(tag => tag._id);
    updatedArticle.append('selectedTags', JSON.stringify(tagsArray));

    // Handle selectedAuthors
    const authorsArray = selectedAuthors.length > 0 ? selectedAuthors.map(author => author.value) : article.selectedAuthors.map(author => author._id);
    updatedArticle.append('selectedAuthors', JSON.stringify(authorsArray));

    // Handle category
    updatedArticle.append('category', selectedCategory ? selectedCategory.value : article.category._id);

    // Handle photos
    if (selectedPhoto) {
        console.log("New photo selected:", selectedPhoto);
        updatedArticle.append('photos', selectedPhoto);
    } else {
        // Use the initial photo if no new photo is selected
        if (initialPhotos.length > 0) {
            const initialPhoto = initialPhotos[0]; // Assuming you want the first initial photo
            console.log("Using initial photo:", initialPhoto);
            updatedArticle.append('photos', initialPhoto); // Append the initial photo
        } else {
            toast.error('A photo is required for submission.');
            setIsUpdating(false);
            return;
        }
    }

    try {
        const response = await onUpdate(article._id, updatedArticle);
        if (response) {
            toast.success('Article updated successfully!');
            onClose();
            resetForm();
        } else {
            throw new Error("Response was undefined");
        }
    } catch (error) {
        console.error('Error updating article:', error);
        toast.error('Error updating article. Please try again.');
    } finally {
        setIsUpdating(false);
    }
};

  const handleTagSelection = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const handleAuthorSelection = (selectedOptions) => {
    setSelectedAuthors(selectedOptions);
  };

  const handleCategorySelection = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  return (
    <Dialog open={open} onClose={() => { resetForm(); onClose(); }} fullWidth maxWidth="md">
      <DialogTitle>
        Update Article
        <FormControlLabel
          control={
            <Switch
              checked={isPublished}
              onChange={() => setIsPublished(!isPublished)}
              color="primary"
            />
          }
          label={isPublished ? 'Published' : 'Draft'}
          labelPlacement="start"
          style={{ float: 'right' }}
        />
      </DialogTitle>

      <DialogContent>
        {selectedArticle && (
          <div className="space-y-4 mt-10">
            <TextField
              label="Headline"
              fullWidth
              value={selectedArticle.headline}
              onChange={(e) => setSelectedArticle({ ...selectedArticle, headline: e.target.value })}
            />
            <TextField
              label="Sub Headline"
              fullWidth
              value={selectedArticle.subheadline}
              onChange={(e) => setSelectedArticle({ ...selectedArticle, subheadline: e.target.value })}
            />
            <ReactQuill
              theme="snow"
              value={selectedArticle.content}
              onChange={(value) => setSelectedArticle({ ...selectedArticle, content: value })}
              className="mb-4"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link'],
                  ['clean'],
                ],
              }}
            />
            <div>
              <label className="block text-xl font-medium mb-2">Choose Tags</label>
              <Select
                isMulti
                options={suggestedTags.map(tag => ({ value: tag._id, label: tag.name }))}
                onChange={handleTagSelection}
                value={selectedTags}
                className="w-full"
              />
            </div>

            <div>
              <label className="block text-xl font-medium mb-2">Selected Tags</label>
              <div className="flex flex-wrap">
                {selectedTags.map(tag => (
                  <Tooltip key={tag.value} title={tag.label} arrow>
                    <span className="bg-blue-200 rounded-full px-3 py-1 mr-2 mb-2">
                      {tag.label}
                    </span>
                  </Tooltip>
                ))}
              </div>
            </div>

            <TextField
              label="YouTube Link"
              fullWidth
              value={selectedArticle.youtubeLink}
              onChange={(e) => setSelectedArticle({ ...selectedArticle, youtubeLink: e.target.value })}
            />
            <div>
              <label className="block text-xl font-medium mb-2">Select Category</label>
              <Select
                options={categories.map(category => ({ value: category._id, label: category.name }))}
                onChange={handleCategorySelection}
                value={selectedCategory}
                className="w-full"
              />
            </div>

            <div>
              <label className="block text-xl font-medium mb-2">Selected Authors</label>
              <div className="flex flex-wrap">
                {selectedAuthors.map(author => (
                  <Tooltip key={author.value} title={author.label} arrow>
                    <span className="bg-green-200 rounded-full px-3 py-1 mr-2 mb-2">
                      {author.label}
                    </span>
                  </Tooltip>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-xl font-medium mb-2">Choose Authors</label>
              <Select
                isMulti
                options={suggestedAuthors.map(author => ({ value: author._id, label: `${author.firstName} ${author.lastName}` }))}
                onChange={handleAuthorSelection}
                value={selectedAuthors}
                className="w-full"
              />
            </div>

            <div>
              <label className="block text-xl font-medium mb-2">Upload New Photo</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setSelectedPhoto(e.target.files[0])}
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => { resetForm(); onClose(); }} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={isUpdating}>
          {isUpdating ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateForm;
