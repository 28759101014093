import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Modal, Select, MenuItem,IconButton, FormControl, InputLabel,Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import UpdateForm from './UpdateForm'; // Adjust the path as necessary
import API_URL from '../../config';
import CreateGallery from './CreateGallery';
const NewsList = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [articleToUpdate, setArticleToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
 

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 20; // Number of articles per page

  useEffect(() => {
    fetchArticles();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [articles, searchTerm, sortBy, sortOrder]);

  // Fetching all articles
  const fetchArticles = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/articles/all`);
      setArticles(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };


 

  // Applying search and sorting filters
  const applyFilters = () => {
    let filtered = [...articles];

    // Search Filter
    if (searchTerm) {
      const searchTermLowerCase = searchTerm.toLowerCase();
      filtered = filtered.filter((article) => article.headline.toLowerCase().includes(searchTermLowerCase));
    }

    // Sorting by date
    filtered.sort((a, b) => {
      if (sortBy === 'date') {
        return sortOrder === 'asc'
          ? new Date(a.createdDate) - new Date(b.createdDate)
          : new Date(b.createdDate) - new Date(a.createdDate);
      }
      return 0;
    });

    setFilteredArticles(filtered);
  };

  // Handle article deletion
  const handleDeleteArticle = async () => {
    try {
      await axios.delete(`${API_URL}/api/articles/${articleToDelete._id}`);
      setArticleToDelete(null);
      setShowDeleteModal(false);
      fetchArticles();
    } catch (error) {
      console.error('Error deleting article:', error);
    }
  };

  const handleShowDeleteModal = (article) => {
    setArticleToDelete(article);
    setShowDeleteModal(true);
  };

  const handleHideDeleteModal = () => {
    setArticleToDelete(null);
    setShowDeleteModal(false);
  };

  // Handle article update
  const handleShowUpdateModal = (article) => {
    setArticleToUpdate(article);
    setShowUpdateModal(true);
  };

  const handleUpdateArticle = async (articleId, formData) => {
    try {
      await axios.put(`${API_URL}/api/articles/update/${articleId}`, formData);
      fetchArticles(); // Refresh articles after update
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const resetUpdateForm = () => {
    setArticleToUpdate(null);
    setShowUpdateModal(false);
  };

  // Pagination Logic
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  return (
    <div className="container mx-auto mt-5">
      <h2 className="text-center mb-4 text-2xl font-bold">News List</h2>

      {/* Search Section */}
      <div className="mb-3 flex items-center">
        <TextField
          variant="outlined"
          label="Search by headline..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mr-2"
        />
        <FormControl variant="outlined" className="mr-2">
          <InputLabel>Sort by</InputLabel>
          <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} label="Sort by">
            <MenuItem value="date">Date</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="mr-2">
          <InputLabel>Order</InputLabel>
          <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} label="Order">
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>
      </div>

 {/* Articles Table */}
<div className="overflow-x-auto">
  <table className="min-w-full bg-white border border-gray-300">
    <thead>
      <tr className="bg-gray-200">
        <th className="border border-gray-300 px-4 py-2">Headline</th>
        <th className="border border-gray-300 px-4 py-2">Status</th>
        <th className="border border-gray-300 px-4 py-2">Actions</th>
      </tr>
    </thead>
    <tbody>
      {currentArticles.map((article) => (
        <tr key={article._id}>
          <td className="border border-gray-300 px-4 py-2">{article.headline}</td>
          <td className="border border-gray-300 px-4 py-2">{article.status}</td>
          <td className="border border-gray-300 px-4 py-2">
            <div className="flex items-center space-x-2">
              <Tooltip title="Edit" arrow>
                <IconButton
                  onClick={() => handleShowUpdateModal(article)}
                  color="primary"
                  aria-label="Edit article"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <IconButton
                  onClick={() => handleShowDeleteModal(article)}
                  color="error"  // Changed to error for clarity
                  aria-label="Delete article"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


      {/* Pagination */}
      <div className="flex justify-between mt-4">
        <Button
          variant="contained"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredArticles.length / articlesPerPage)))}
          disabled={currentPage >= Math.ceil(filteredArticles.length / articlesPerPage)}
        >
          Next
        </Button>
      </div>

      {/* Delete Modal */}
      <Modal open={showDeleteModal} onClose={handleHideDeleteModal}>
        <div className="flex justify-center items-center h-full">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold">Confirm Deletion</h2>
            <p>Are you sure you want to delete this article?</p>
            <div className="mt-4 flex justify-between">
              <Button variant="contained" color="secondary" onClick={handleDeleteArticle}>
                Confirm
              </Button>
              <Button variant="outlined" color="primary" onClick={handleHideDeleteModal}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Update Form Modal */}
      <UpdateForm
        open={showUpdateModal}
        onClose={resetUpdateForm}
        article={articleToUpdate}
        onUpdate={handleUpdateArticle}
      />
    </div>
  );
};

export default NewsList;